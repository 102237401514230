import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { UserClaims } from '@okta/okta-auth-js';
import { Observable, Subscription, shareReplay, tap } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { SurnamePipe } from "../../pipes/surname";

@Component({
  standalone: true,
  selector: 'app-left-panel',
  templateUrl: './left-panel.component.html',
  styleUrls: ['./left-panel.component.scss'],
  imports: [
    CommonModule,
    MatIconModule,
    TranslateModule,
    RouterModule,
    SurnamePipe
],
})
export class LeftPanelComponent implements OnDestroy {
  public userFullName: string = '';
  public activeTab?: 'personal-info' | 'vacations' | 'absence' | 'rcp' | 'equipment' | 'idea' | 'salary' | 'pit' | 'calc' | 'assessment' | 'loan';
  private sub: Subscription;
  public user$!: Observable<UserClaims>;
  private readonly router = inject(Router);
  private readonly authService = inject(AuthService);

  constructor() {
    this.user$ = this.authService.getUser().pipe(
      tap(t => this.appLogin(t))
    );
    this.sub = this.router.events.subscribe(c => {
      if (c instanceof NavigationEnd) {
        this.setActiveTab();
      }
    });
  }


  ngOnDestroy(): void {
    if (this.sub)
      this.sub.unsubscribe();
  }


  appLogin(user: UserClaims): void {
    const employeeId = parseInt(user['employee_id'].toString(), 10);
    if (employeeId > 0) {
      this.authService.appLogin(employeeId).subscribe();
    }
  }


  onLogoutClick() {
    this.authService.logout().subscribe();
    //await this._oktaAuth.signOut();
  }


  private setActiveTab(): void {
    switch (this.router.url) {
      case '/personal-info': this.activeTab = 'personal-info'; break;
      case '/vacation': this.activeTab = 'vacations'; break;
      case '/absence': this.activeTab = 'absence'; break;
      case '/rcp': this.activeTab = 'rcp'; break;
      case '/equipment': this.activeTab = 'equipment'; break;
      case '/idea': this.activeTab = 'idea'; break;
      case '/loan': this.activeTab = 'loan'; break;
      case '/assessment': this.activeTab = 'assessment'; break;
      case '/salary': this.activeTab = 'salary'; break;
      case '/pit': this.activeTab = 'pit'; break;
      default: this.activeTab = undefined;
    }
  }

}
